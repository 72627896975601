<template>
  <div class="misc-inner p-2">
    <div class="container px-0">
      <b-navbar
        toggleable="lg"
        :variant="skin == 'light' ? 'light' : 'black'"
        class="px-0"
      >
        <b-navbar-brand href="#">
          <b-link class="brand-logo" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="skin == 'light' ? appDarkLogo : appLightLogo"
            />
          </b-link>
        </b-navbar-brand>

        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

        <!-- <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'about-us' }">About</b-nav-item>
            <b-nav-item :to="{ name: 'contact-us' }">Contact Us</b-nav-item>
            <b-nav-item :to="{ name: 'terms' }">Terms of use</b-nav-item>
            <b-nav-item :to="{ name: 'privacy-policy' }"
              >Privacy Policy</b-nav-item
            >
            <b-nav-item-dropdown right class="d-none">
              <template #button-content>
                <em>User</em>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item href="#">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse> -->
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { NavbarPlugin } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import store from "@/store/index";

export default {
  components: {
    NavbarPlugin,
  },
  setup() {
    // App Name
    const { appName, appDarkLogo, appLightLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
      appLightLogo,
    };
  },
  data() {
    return {
      username: "Initial value",
      skin: store.state.appConfig.layout.skin,
    };
  },
};
</script>
